import logo from './chi-logo-white.svg';
import './App.css';
import background from './AdobeStock_219080081.jpeg';
import { useState } from 'react';
import { send } from 'emailjs-com';

function App() {
  const handleChange = (e) => {
    console.log(e);
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  const [toSend, setToSend] = useState({
    fname: '',
    lname: '',
    email: '',
    country: '',
    message: '',
  });
  const [isSubmittedSuccess, setIsSubmittedSuccess] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();
    send('service_fgdx75y', 'template_w9ivrf7', toSend, 'cKMMSskNUTX2-bClf').then(
      (result) => {
        console.log(result.text);
        setIsSubmittedSuccess(true);
      },
      (error) => {
        console.log(error.text);
      }
    );
  };
  return (
    <div
      className="App"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        color: '#f5f5f5',
      }}
    >
      <div id="page_container" className="body-container">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
        </header>
        <div>
          <div id="body_container">
            <section>
              <div>
                <b>Compass Holdings International (CHI)</b> is a leader in the design, manufacture,
                distribution and service of high quality innovative equipment.
              </div>
            </section>
            <section>
              <h1>OUR STORY</h1>
              <div>
                As a family owned company, we currently own the{' '}
                <a className="intext-link" href="https://arrowquip.com">
                  Arrowquip
                </a>{' '}
                brand which specializes in cattle management equipment across North America, Europe
                and Australia. Over the last 10 years, this brand has experienced accelerated growth
                to become a mature, recognized brand in the industry.
              </div>
            </section>
            <section>
              <h2>WHAT WE STAND FOR?</h2>
              <ul>
                <li>
                  <span>Agile in our approach</span>
                </li>
                <li>
                  <span>Strong Financials & Cash Flow positive</span>
                </li>
                <li>
                  <span>Experienced Leadership & Management Teams</span>
                </li>
                <li>
                  <span>Believe good culture in a company is foundational</span>
                </li>
              </ul>
            </section>
            <section>
              <h2>CONTACT US & LEARN MORE</h2>
              {!isSubmittedSuccess ? (
                <div id="flexContainer">
                  <form id="contact_form" onSubmit={sendEmail}>
                    <div className="contact_form_div">
                      <input
                        type="text"
                        name="fname"
                        placeholder="First Name*"
                        value={toSend.fname}
                        onChange={handleChange}
                        required
                      />
                      <input
                        type="text"
                        name="lname"
                        placeholder="Last Name*"
                        value={toSend.lname}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="contact_form_div">
                      <input
                        type="email"
                        name="email"
                        placeholder="Email*"
                        value={toSend.email}
                        onChange={handleChange}
                        required
                      />
                      <input
                        type="text"
                        name="country"
                        placeholder="Country"
                        value={toSend.country}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="permission_label_text">
                      <input
                        type="checkbox"
                        id="contact_permission"
                        name="contact_permission"
                        value=""
                      />
                      <label htmlFor="contact_permission">
                        {' '}
                        Allow CHI to keep in contact with me. I have read and accept CHI's{' '}
                        <a className="intext-link" href="https://arrowquip.com/privacy-policy">
                          Privacy Policy
                        </a>{' '}
                        and I understand I can unsubscribe at any time.
                      </label>
                    </div>
                    <textarea
                      className="text-area"
                      form="contact_form"
                      type="text"
                      placeholder=" What can we help you with?"
                      name="message"
                      value={toSend.message}
                      onChange={handleChange}
                    ></textarea>
                    <input
                      id="contact_submit"
                      className="form-submit"
                      type="submit"
                      value="CONTACT US"
                    />
                  </form>
                </div>
              ) : (
                <div>Request Successfully Submitted!</div>
              )}
            </section>
          </div>
        </div>
      </div>
      {/* page container */}
      <footer>
        <div className="footer">
          &#169;2022 Compass Holdings International. All Rights Reserved.
          <br></br>
          <a className="footer-links" href="https://arrowquip.com/privacy-policy">
            Privacy Policy
          </a>
        </div>
      </footer>
    </div>
  );
}

export default App;
